import Vue from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

function createMetaTag(tags) {
  tags.map((tagDef) => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach((key) => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));
}

router.afterEach((from) => {
  document.title = from.meta.title || 'ToruPrints';
  const nearestWithMeta = from.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));

  if (nearestWithMeta) {
    createMetaTag(nearestWithMeta.meta.metaTags);
  }

  if (from.meta.metaTags && nearestWithMeta.meta.title !== from.meta.title) {
    createMetaTag(from.meta.metaTags);
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
