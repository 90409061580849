<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <router-link
          :to="{ name: 'home' }">
            <v-img
              alt="ToruPrints Logo"
              class="shrink mr-2"
              contain
              src="./assets/logo.svg"
              transition="scale-transition"
              width="25"
            />
        </router-link>
        <router-link
          :to="{ name: 'home' }"
          class="text-h5 white--text text-decoration-none">
          ToruPrints
        </router-link>
      </div>
      <v-spacer />
      <div class="text-overline secondary--text font-weight-black">
        <router-link
          :to="{ name: 'portfolio' }"
          class="secondary--text text-decoration-none">
          Portfolio
        </router-link> |
        <router-link
          :to="{ name: 'contact' }"
          class="secondary--text text-decoration-none">
          Contact
        </router-link>
      </div>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
    <v-footer
      color="primary"
      dark>
      <v-container>
        <v-row>
          2021 - ToruPrints
          <v-spacer />
          <v-btn color="secondary" icon small href="http://www.instagram.com/toruprints" target="_blank" title="Instagram link">
            <v-icon>
              mdi-instagram
            </v-icon>
          </v-btn>
          <v-btn color="secondary" icon small href="http://www.twitter.com/timrunfast" target="_blank" title="Twitter link">
            <v-icon>
              mdi-twitter
            </v-icon>
          </v-btn>
          <v-btn color="secondary" icon small href="http://www.twitch.com/toruprints" target="_blank" title="Twitch link">
            <v-icon>
              mdi-twitch
            </v-icon>
          </v-btn>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import store from './data/Store.json';

export default {
  name: 'App',
  methods: {
    ...mapActions([
      'setProfolioInfo',
      'setMainPhoto',
    ]),
  },
  mounted() {
    this.setProfolioInfo({
      items: store.items.sort((a, b) => ((a.id > b.id) ? -1 : 1)),
      baseUrl: store.baseUrl,
    });
    this.setMainPhoto({ url: store.mainPhoto });
  },
};
</script>
