import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

function createDescription(description) {
  return [
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:description',
      content: description,
    },
  ];
}

function createTitle(title) {
  return [
    {
      property: 'og:title',
      content: title,
    },
  ];
}

function defaultTags() {
  return [
    {
      name: 'robots',
      content: 'index, follow',
    },
  ];
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'ToruPrints - Prop and trinket commissions',
      metaTags: [
        ...createDescription('A website dedicated to showing the props or trinkets created for my clients, and to provide a way for others to request an item'),
        ...defaultTags(),
        ...createTitle('ToruPrints - Prop and trinket commissions'),
      ],
    },
  },
  {
    path: '/portfolio',
    component: () => import(/* webpackChunkName: "portfolio" */ '../views/Portfolio.vue'),
    meta: {
      metaTags: [
        ...createDescription('A page showing the items that have been created.'),
        ...defaultTags(),
      ],
    },
    children: [
      {
        path: 'all',
        name: 'portfolio',
        component: () => import(/* webpackChunkName: "portfolio" */ '../components/PortfolioFeed.vue'),
        meta: {
          title: 'ToruPrints - Portfolio',
          metaTags: [
            ...createTitle('ToruPrints - Portfolio'),
          ],
        },
      },
      {
        path: 'view/:viewedId',
        name: 'portfolio-item',
        component: () => import(/* webpackChunkName: "portfolio" */ '../components/PortfolioItem.vue'),
        props: (route) => {
          const viewedId = Number.parseInt(route.params.viewedId, 10);
          if (Number.isNaN(viewedId)) {
            return 0;
          }

          return { viewedId };
        },
        meta: {
          title: 'ToruPrints - Portfolio - ',
          metaTags: [
            ...createTitle('ToruPrints - Portfolio'),
          ],
        },
      },
    ],
  },
  {
    path: '/contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
    meta: {
      metaTags: [
        ...createDescription('A page allowing people to get in contact with ToruPrints.'),
        ...defaultTags(),
      ],
    },
    children: [
      {
        path: '/',
        name: 'contact',
        component: () => import(/* webpackChunkName: "contact" */ '../components/ContactForm.vue'),
        meta: {
          title: 'ToruPrints - Contact',
          metaTags: [
            ...createTitle('ToruPrints - Contact'),
          ],
        },
      },
      {
        path: 'thanks',
        name: 'contact-thanks',
        component: () => import(/* webpackChunkName: "contact" */ '../components/ContactThanks.vue'),
        meta: {
          title: 'ToruPrints - Contact - Thanks',
          metaTags: [
            ...createTitle('ToruPrints - Contact - Thanks'),
          ],
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
