export default {
  methods: {
    setImage(imageSource) {
      const tag = document.createElement('meta');
      tag.setAttribute('property', 'og:image');
      tag.setAttribute('content', imageSource);

      tag.setAttribute('data-vue-router-controlled', '');

      document.head.appendChild(tag);
    },
  },
};
