import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    portfolioItems: [],
    baseUrl: '',
    mainPhoto: '',
  },
  mutations: {
    setPortfolioItems(state, items) {
      state.portfolioItems = items;
    },
    setBaseUrl(state, url) {
      state.baseUrl = url;
    },
    setMainPhoto(state, url) {
      state.mainPhoto = url;
    },
  },
  actions: {
    setProfolioInfo({ commit }, payload) {
      commit('setPortfolioItems', payload.items);
      commit('setBaseUrl', payload.baseUrl);
    },
    setMainPhoto({ commit }, payload) {
      commit('setMainPhoto', payload.url);
    },
  },
  modules: {
  },
});
