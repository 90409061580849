<template>
  <div>
    <v-sheet class="px-4 pt-4 pb-3">
      <v-container v-if="image">
        <v-row>
          <v-col
            md="4"
            sm="4"
            xs="12"
            lg="4"
            xl="4">
            <transition name="fade">
              <v-card elevation="0" v-if="image !== null">
                  <router-link :to="{ name: 'portfolio-item', params: { viewedId: image.id } }">
                    <v-img
                      :src="image.imageSrc"
                      contain
                    />
                  </router-link>
                <router-link :to="{ name: 'portfolio-item', params: { viewedId: image.id } }">
                  <div class="text-caption">
                    {{ image.title }}
                  </div>
                </router-link>
              </v-card>
            </transition>
          </v-col>
          <v-col
            md="8"
            sm="8"
            xs="12"
            lg="8"
            xl="8">
            <v-sheet class="px-6 pt-6 pb-4">
              <div class="text-h5">Welcome to ToruPrints</div>
            </v-sheet>
            <v-sheet class="px-6 pt-6 pb-4">
              <!-- eslint-disable-next-line max-len -->
              <div class="text-body-1">I'm a UK based creator who creates props for cosplay and trinkets (and to hang on my wall and admire).
                This is a site dedicated to the commissions that I've made.
                For ongoing projects please see my <a href="http://www.instagram.com/toruprints" target="_blank">Instagram</a> account
              </div>
            </v-sheet>
            <v-sheet class="px-6 pt-6 pb-4">
              <div class="text-body-2">
                <!-- eslint-disable-next-line max-len -->
                If you're looking for a commission or general enquiry contact me via <router-link :to="{ name: 'contact' }">this form</router-link>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import openGraphImage from '../mixins/OpenGraphImage';

export default {
  mixins: [openGraphImage],
  data() {
    return {
      image: {},
      photos: [],
      timer: null,
    };
  },
  computed: {
    ...mapState([
      'mainPhoto',
      'portfolioItems',
      'baseUrl',
    ]),
    imageDimension() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 220;
        case 'sm': return 400;
        case 'md': return 500;
        case 'lg': return 600;
        case 'xl': return 800;
        default: return 500;
      }
    },
  },
  mounted() {
    const self = this;

    this.photos = this.portfolioItems
      .map((item) => item.photos
        .map((photo) => ({ id: item.id, title: item.title, imageSrc: `${this.baseUrl}/${item.folder}/${photo.source}` }))).flat();
    this.setImage();
    this.timer = setInterval(() => self.setImage(), 5000);
  },
  unmounted() {
    clearInterval(this.timer);
  },
  methods: {
    setImage() {
      this.image = null;
      this.image = this.photos[Math.floor(Math.random() * this.photos.length)];
    },
  },
  watch: {
    portfolioItems() {
      this.photos = this.portfolioItems
        .map((item) => item.photos
          .map((photo) => ({ id: item.id, imageSrc: `${this.baseUrl}/${item.folder}/${photo.source}` }))).flat();
      this.setImage();
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  .welcome-title {
    font-size: 1.2em;
    font-weight: 700;
    margin: 10px 10px 20px 10px;
  }

  .blurb {
    margin: 5px 15px;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
